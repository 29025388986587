import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
} from "@mui/material";
import React from "react";
import { useOrganization } from "../../../../../../contexts/OrganizationContext";
import ContainerInformation from "../../../shared/ContainerInformation";

const PlanAndFeaturesOrganization: React.FC = () => {
  const {
    planName,
    setPlanName,
    price,
    setPrice,
    priceUser,
    setPriceUser,
    documentHistory,
    setDocumentHistory,
    formReviewFlow,
    setFormReviewFlow,
    redlining,
    setRedlining,
    sharelink,
    setSharelink,
    whatsappLink,
    setWhatsappLink,
    whatsappNotification,
    setWhatsappNotification,
    proofReminder,
    setProofReminder,
    proofReminderFrequency,
    setProofReminderFrequency,
    plansList,
    whatsAppMonthlyQuota, 
    setWhatsAppMonthlyQuota,
    signatureWith2FA,
    setSignatureWith2FA,
    signatureWithLoginPassword,
    setSignatureWithLoginPassword,
    anonymizeProofSubjectsInfo,
    setAnonymizeProofSubjectsInfo,
  } = useOrganization();

  return (
    <div style={{ padding: "24px 0 0 0" }}>
      <FormControl sx={{ width: 230 }}>
        <InputLabel id="plan-checkbox-label">Plano</InputLabel>
        <Select
          labelId="plan-select-label"
          id="plan-select"
          value={planName}
          label="Plano"
          onChange={(e) => setPlanName(e.target.value)}
        >
          {plansList.map((el: any) => (
            <MenuItem value={el.name} key={el.id}>
              {el.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <ContainerInformation>
        <TextField
          label="Preço"
          variant="outlined"
          value={Number(price)}
          type="number"
          InputProps={{ inputProps: { min: 0 } }}
          onChange={(e) => setPrice(Number(e.target.value))}
        />
      </ContainerInformation>

      <ContainerInformation>
        <TextField
          label="Preço por usuário"
          variant="outlined"
          value={Number(priceUser)}
          type="number"
          InputProps={{ inputProps: { min: 0 } }}
          onChange={(e) => setPriceUser(Number(e.target.value))}
        />
      </ContainerInformation>

      <ContainerInformation>
        <TextField
          label="Cota mensal de notificações via WhatsApp"
          variant="outlined"
          value={whatsAppMonthlyQuota}
          type="number"
          InputProps={{ inputProps: { min: 0 } }}
          onChange={(e) => setWhatsAppMonthlyQuota(Number(e.target.value))}
        />
      </ContainerInformation>

      <ContainerInformation title="Revisão de formulários">
        <Switch
          checked={formReviewFlow}
          onChange={(e) => setFormReviewFlow(e.target.checked)}
        />
      </ContainerInformation>

      <ContainerInformation title="Histórico do documento">
        <Switch
          checked={documentHistory}
          onChange={(e) => setDocumentHistory(e.target.checked)}
        />
      </ContainerInformation>

      <ContainerInformation title="Revisão de contratos">
        <Switch
          checked={redlining}
          onChange={(e) => setRedlining(e.target.checked)}
        />
      </ContainerInformation>

      <ContainerInformation title="Link de compartilhamento">
        <Switch
          checked={sharelink}
          onChange={(e) => setSharelink(e.target.checked)}
        />
      </ContainerInformation>

      <ContainerInformation title="Compartilhar link por WhatsApp Web">
        <Switch
          checked={whatsappLink}
          onChange={(e) => setWhatsappLink(e.target.checked)}
        />
      </ContainerInformation>

      <ContainerInformation title="Notificação automática via WhatsApp">
        <Switch
          checked={whatsappNotification}
          onChange={(e) => setWhatsappNotification(e.target.checked)}
        />
      </ContainerInformation>

      <ContainerInformation title="Lembrete automático de assinatura">
        <Switch
          checked={proofReminder}
          onChange={(e) => setProofReminder(e.target.checked)}
        />
      </ContainerInformation>

      <TextField
        label="Frequência do lembrete"
        variant="outlined"
        value={Number(proofReminderFrequency)}
        type="number"
        InputProps={{ inputProps: { min: 0 } }}
        onChange={(e) => setProofReminderFrequency(Number(e.target.value))}
      />

      <ContainerInformation title="Assinatura com 2FA">
        <Switch
          checked={signatureWith2FA}
          onChange={(e) => setSignatureWith2FA(e.target.checked)}
        />
      </ContainerInformation>

      <ContainerInformation title="Assinatura com Login">
        <Switch
          checked={signatureWithLoginPassword}
          onChange={(e) => setSignatureWithLoginPassword(e.target.checked)}
        />
      </ContainerInformation>

      <ContainerInformation title="Ocultar dados de assinantes">
        <Switch
          checked={anonymizeProofSubjectsInfo}
          onChange={(e) => setAnonymizeProofSubjectsInfo(e.target.checked)}
        />
      </ContainerInformation>
    </div>
  );
};

export default PlanAndFeaturesOrganization;
